import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import language from '@/language/index'
import locale from 'element-ui/lib/locale/lang/en'
import VueClipBoard from 'vue-clipboard2'
// import echarts from "echarts";
// let echarts = require('echarts')
// Vue.prototype.$echarts = echarts;

const version = 1// 1 英文版，2 气候商店，3 印尼版
Vue.use(VueClipBoard)

Vue.config.productionTip = false
Vue.prototype.$lg = language.toggle(version)
var languagetype = localStorage.getItem('language');



if (languagetype == 'en-us' || languagetype == 'id-id') {
    Vue.use(ElementUI, { locale })
} else {
    Vue.use(ElementUI);
}

const tolink = (url, type) => {
    // type 1 需要登陆 2 不需要登陆
    if (type == 1) {
        if (localStorage.getItem('token')) {
            router.push(url);
        } else {
            router.push('/login');
        }
    } else {
        router.push(url);
    }
}

const building = () => {
    Message({
        message: language.toggle().building,
        duration: 3000
    })
}

Vue.prototype.$carbon = {
    tolink,
    // baseurl: 'https://dev.zerocarbonex.com',
    baseurl: 'https://api.zerocarbonex.com',
    // baseurl: 'http://192.168.88.169',
    building,
    version:version// 1 英文版，2 气候商店，3 印尼版
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')